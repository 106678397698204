import Home from './components/pages/Home';
import { LanguageContextProvider } from './context/languageContext';
import React from 'react';
import { ThemeChangeContextProvider } from './context/themeChangeContext';

function App() {
  return (
    <>
      <ThemeChangeContextProvider>
        <LanguageContextProvider>
          <Home />
        </LanguageContextProvider>
      </ThemeChangeContextProvider>
    </>
  );
}

export default App;
